/* global angular */
angular.module('wantoli',[
    'wantoli.controllers',
    'wantoli.directives',
    'wantoli.services'
    ])
    .config(function($interpolateProvider) {
        $interpolateProvider.startSymbol('{%');
        $interpolateProvider.endSymbol('%}');
    });
;