/* global angular */
angular.module('wantoli.services',[])
.service('wantoliErrors', function(){
  this.getErrors = function(data,prefix){
    var _prefix = prefix ? prefix : "";
    var _errors = [];
    
    if(data.errors)
    {
        var errors = data.errors;
        for (var prop in errors) {
            for(var i = 0; i < errors[prop].length; i++)
            { 
                _errors[_prefix+""+prop] = errors[prop][i] + "\n";
            }
        }
    }
    
    return _errors;
  }
})
.service('ngCopy', ['$window', function ($window) {
	var body = angular.element($window.document.body);
	var textarea = angular.element('<textarea/>');
	textarea.css({
		position: 'fixed',
		opacity: '0'
	});

	return function (toCopy) {
		textarea.val(toCopy);
		body.append(textarea);
		textarea[0].select();

		try {
			var successful = document.execCommand('copy');
			if(successful) 
			    return true;
			else
			    throw successful;
		} catch (err) {
			window.prompt("Copy to clipboard: Ctrl+C, Enter", toCopy);
		}

		textarea.remove();
	}
}])
;