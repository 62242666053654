/* global angular */
angular.module('wantoli.directives',[])
.directive('devFileImage',function() {
    return {
       restrict: 'A',
       replace: true,
       link: function(scope, elm, attrs) {
          elm.ready(function() {
            var img     = elm.find('.preview');
            var oldBg   = img.css('background-image');
            var input   = elm.find('input');
            img.click(function(){
                input.click();
            })
            
            input.on('change',function(){
                if (input[0].files && input[0].files[0]) {
                    var reader = new FileReader();
                    reader.onload = function(e) {
                      img.css('background-image', 'url('+e.target.result+')');
                    }
                
                    reader.readAsDataURL(input[0].files[0]);
                  }
                  else
                  {
                    img.css('background-image', oldBg);
                  }
            });
          });
      }
    };
})
.directive('ngClickCopy', ['ngCopy', function (ngCopy) {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			element.bind('click', function (e) {
				var tc = ngCopy(attrs.ngClickCopy);
				if(tc)
				    swal({text : 'Link copied to clipboard !',type : 'success'});
			});
		}
	}
}])
.directive('watchFooter',function() {
    return {
       restrict: 'A',
       link: function(scope, elm, attrs) {
          elm.ready(function() {
              
            stekFooter();
            $(window).on('resize',stekFooter);
            
            function stekFooter()
            {
                var navbar = $('.dev-navbar').outerHeight(),
                footer = $('.dev-footer').outerHeight(),
                wind   = $(window).height(),
                sum    = wind - (navbar + footer);
                
                $('.dev-page-content').css('min-height',sum);
            }
            
          });
      }
    };
})
.directive('countDown',function() {
    return {
       restrict: 'A',
       link: function(scope, elm, attrs) {
          elm.ready(function() {
            var date = ''+attrs['date'];
            elm.countdown(date, function(event) {
                $(this).find('.weeks .cnt').html(event.strftime('%w'));
                $(this).find('.days .cnt').html(event.strftime('%d'));
                $(this).find('.hours .cnt').html(event.strftime('%H'));
                $(this).find('.min .cnt').html(event.strftime('%M'));
                $(this).find('.sec .cnt').html(event.strftime('%S'));
              });
            
          });
      }
    };
})
;