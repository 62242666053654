/* global angular */
angular.module('wantoli.controllers',[])
.controller('authController',authController)
.controller('eventController',eventController)
.controller('profileController',profileController);

/* auth Controller */
authController.$inject = ["$scope","$http","$window","wantoliErrors"];
function authController($scope,$http,$window,wantoliErrors)
{
    var ctr = this;
    ctr.form = [];
    ctr.errors = [];
    
    ctr.doLogin = function(){
        
        var credentials = {
            email : ctr.form.email,
            password : ctr.form.psw
        };
        
        $http({
          method: 'POST',
          url: '/login',
          data: credentials
        }).then(function success(response) {
            var data = response.data;
            if(data.status)
            {
                $window.location.reload();
                /*swal({
                  text :data.message,
                  type:'success'
                }).then(function(){
                    $window.location.reload();
                },function(){
                    $window.location.reload();
                });*/
                
            }
            else
            {
                if(data.user_id)
                {
                    swal({
                      title: 'Confirm acount',
                      text: 'we have sent you a confirmation link please check your email.',
                      type:'warning',
                      confirmButtonText: 'Resend mail',
                      cancelButtonText: 'Cancel',
                      showCancelButton: true
                    }).then(function(){
                            $http({
                              method: 'POST',
                              url: '/resend-user-mail',
                              data: {id : data.user_id}
                            }).then(function success(response) {
                                var data = response.data;
                                if(data.status)
                                {
                                    swal({ text :"good", type:'success' });
                                }
                                else
                                {
                                    swal({ text :"not good", type:'error' });
                                }
                          }, function error(response) {
                            
                          });
                    },function(){
                        
                    });
                }
                else
                {
                    swal({ text :data.message, type:'error' });
                }
            }
          }, function error(response) {
            
          });
    };
     
    ctr.doRegister = function(){
        ctr.errors = [];
        var data = {
            firstname   : ctr.form.firstname,
            lastname    : ctr.form.lastname,
            email       : ctr.form.email,
            password    : ctr.form.password,
            password_confirmation    : ctr.form.password2
        }
        
        $http({
          method: 'POST',
          url: '/register',
          data: data
        }).then(function success(response) {
            var data = response.data;
            if(data.status)
            {
                swal({ text :data.message, type:'success' })
                .then(function(){
                    $window.location.href = "/login";
                },function(){
                    $window.location.href = "/login";
                });
            }
            else
            {
                ctr.errors = wantoliErrors.getErrors(data);
            }
          }, function error(response) {
            
          });
    };
    
    ctr.showEmailForm = function(){
        angular.element('#mail-modal').modal();
    }
    
    ctr.activateFb = function(id){
        swal({
              title: 'Confirm facebook acount',
              text: 'we have sent you a confirmation link please check your email.',
              type:'warning',
              confirmButtonText: 'Resend mail',
              cancelButtonText: 'Cancel',
              showCancelButton: true
            }).then(function(){
                    $http({
                      method: 'POST',
                      url: '/resend-fb-mail',
                      data: {id : id}
                    }).then(function success(response) {
                        var data = response.data;
                        if(data.status)
                        {
                            swal({ text :"good", type:'success' });
                        }
                        else
                        {
                            swal({ text :"not good", type:'error' });
                        }
                  }, function error(response) {
                    
                  });
            },function(){
                
            });
    }
    
    ctr.createFbUser = function()
    {
        $http({
          method: 'POST',
          url: '/create-fb-user',
          data: { email : ctr.form.email }
        }).then(function success(response) {
            var data = response.data;
            if(data.status)
            {
                //$window.location.reload();
                angular.element('#mail-modal').modal("hide");
                ctr.activateFb(data.account_id);
            }
            else
            {
                ctr.errors = wantoliErrors.getErrors(data,"fb_");
            }
          }, function error(response) {
            
          });
    }
}

/* end auth controller */

/* event Controller */
eventController.$inject = ["$scope","$http","$window","$timeout","wantoliErrors"];
function eventController($scope,$http,$window,$timeout,wantoliErrors)
{
    var ctr         = this;
    ctr.event       = false;
    ctr.events      = [];
    ctr.singleEvent = false;
    ctr.singleEventId = false;
    ctr.editEvent   = false;
    ctr.editeGift   = false;
    ctr.showGiftForm= false;
    ctr.errors      = [];
    ctr.gifts       = [];
    ctr.short_link   = "wnt.li/xxxxxxx";
    ctr.disableLink = true;
    ctr.giftPreview = '/images/gifts/gift-holder.png';
    ctr.filterEvents = true;
    
    /* add init class to the event/gift form ( for the transition effect ) */
    $timeout(function() {
        ctr.eventForm = true;
        ctr.giftForm  = true;
    }, 500);
    
    ctr.getEvent = function(event_id){
        $http({
          method: 'POST',
          url: '/get-event',
          data: {id : event_id}
        }).then(function success(response) {
            var data = response.data;
            if(data.status)
                ctr.initEvent(data.event);
            else
                $window.location = '/event';
        }, function error(response) {
            
        });
        
    }
    
    ctr.initEvent = function(event){
        ctr.event           = event;
        ctr.event_title      = event.title;
        ctr.event_type       = event.type;
        ctr.event_date       = event.date;
        ctr.event_address    = event.address;
        ctr.short_link       = event.shortlink;
        ctr.disableLink     = false;
        ctr.initForm        = false;
        if(event.gifts)
            ctr.gifts = event.gifts;
    }
    
    ctr.saveEvent = function(){
        ctr.errors = [];
        var url = '/event/store';
        if(ctr.editEvent)
            url = '/event/update/'+ctr.event.id;
            
        var data = {
            title   : ctr.event_title,
            type    : ctr.event_type,
            date    : ctr.event_date,
            address : ctr.event_address,
        }
        
        $http({
          method: 'POST',
          url: url,
          data: data
        }).then(function success(response) {
            var data = response.data;
            if(data.status)
            { 
                if(data.message)
                {
                    swal({ text :data.message, type:'success' });
                }
                ctr.initEvent(data.event);
                ctr.editEvent = false;
            }
            else
            {
                ctr.errors = wantoliErrors.getErrors(data,"event_");
            }
          }, function error(response) {
            
          });
    };
    
    ctr.updateEvent = function(){
        ctr.editEvent   = true;
        ctr.eventForm      = true;
    }
    
    ctr.deleteEvent = function(id){
         swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this!'}); return;
        swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this!',
          type:'warning',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it!',
          showCancelButton: true
        }).then(function(){
            
            $http({
              method: 'POST',
              url: '/delete-event',
              data: {id : id}
            }).then(function success(response) {
                var data = response.data;
                if(data.status)
                {
                    if(data.message)
                    {
                        swal({ text :data.message, type:'success' });
                    }
                    
                    ctr.getEvents();
                }
                else
                {
                    if(data.message)
                    {
                        swal({text :data.message, type:'error' });
                    }
                }
            }, function error(response) {
                
            });
            
        },function(){
            
        });
        
    }
    
    ctr.getSingle = function(id){
        if(id)
        {
            ctr.singleEventId = id;
            
            $http({
              method: 'GET',
              url: '/get-single/'+id
            }).then(function success(response) {
                ctr.singleEvent = response.data;
            }, function error(response) {
                
            });
        }
    }
    
    ctr.getEvents = function(){
        $http({
          method: 'GET',
          url: '/get-events'
        }).then(function success(response) {
            ctr.events = response.data;
        }, function error(response) {
            
        });
    }
    
    $scope.uploadedFile = function(element) {
     $scope.$apply(function($scope) {
       ctr.file = element.files[0];
     });
    }
    
    ctr.toggleGiftForm = function(){
        ctr.showGiftForm = !ctr.showGiftForm;
    }
    
    ctr.saveGift = function(){
        
        if(ctr.event)
        {
            var url = '/gift/store';
            if(ctr.editeGift)
                url = '/gift/update/'+ctr.gift.id;
            
            var fd = new FormData();
            if(ctr.file)
                fd.append('file',ctr.file);
                
             var data = {
              title         : ctr.gift_title,
              desc          : ctr.gift_desc,
              link          : ctr.gift_link,
              event_id      : ctr.event.id
             };
            
             fd.append("data", JSON.stringify(data));
            
             $http({
              method: 'POST',
              url: url,
              data: fd,
              withCredentials : false,
              headers : {
              'Content-Type' : undefined
              },
              transformRequest : angular.identity
             }).then(function success(response) {
                var data = response.data; 
                if(data.status)
                { 
                    if(data.message)
                    {
                        swal({ text :data.message, type:'success'});
                    }
                    ctr.getEvent(ctr.event.id);
                    ctr.resetGiftForm();
                    ctr.showGiftForm = false;
                }
                else
                {
                    ctr.errors = wantoliErrors.getErrors(data,"gift_");
                }
              }, function error(response) {
                
              });
        }
        else
        {
            alert("no event");
        }
        
    }
    
    ctr.getGift = function(id)
    {
        $http({
          method: 'POST',
          url: '/get-gift',
          data: {id : id}
        }).then(function success(response) {
            ctr.gift = response.data;
            if(ctr.gift)
            {
                ctr.gift_title   = ctr.gift.title;
                ctr.gift_desc    = ctr.gift.desc;
                ctr.gift_link    = ctr.gift.link;
                ctr.showGiftForm = true;
                ctr.giftPreview  = ctr.gift.img;
            }
        }, function error(response) {
            
        });
    }
    
    ctr.addGift = function()
    {
        ctr.resetGiftForm();
        ctr.editeGift = false;
        ctr.showGiftForm = true;
    }
    
    ctr.updateGift = function(id)
    {
        ctr.resetGiftForm();
        ctr.getGift(id);
        ctr.editeGift = true;
    }
    
    ctr.deleteGift = function(id)
    {
        swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this!',
          type:'warning',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it!',
          showCancelButton: true
        }).then(function(){
            
            $http({
              method: 'POST',
              url: '/delete-gift',
              data: {id : id}
            }).then(function success(response) {
                var data = response.data;
                if(data.status)
                {
                    if(data.message)
                    {
                        swal({ text :data.message, type:'success' });
                    }
                    
                    ctr.getEvent(ctr.event.id);
                }
                else
                {
                    if(data.message)
                    {
                        swal({text :data.message, type:'error' });
                    }
                }
            }, function error(response) {
                
            });
            
        },function(){
            
        });
    }
    
    ctr.checkGift = function(gift_id)
    {
        swal({
            title   : "Check gift",
            text    : "lorem ipsum",
            type    : 'warning',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true
        }).then(function() {
                $http({
                  method: 'POST',
                  url: '/check-gift',
                  data: {id : gift_id}
                }).then(function success(response) {
                    var data = response.data;
                    if(data.status)
                    {
                        if(ctr.singleEventId)
                            ctr.getSingle(ctr.singleEventId);
                        swal({ text :data.message, type:'success'});
                    }
                    else
                    {
                        if(!data.type)
                        {
                            swal({ text :data.message, type:'error'});
                        }
                        else
                        {
                            if(data.type == "AUTH")
                                angular.element('#auth-modal').modal();
                        }
                        
                    }
                    
                }, function error(response) {
                    
                });
        },function(){
            
        });
        
    }
    
    ctr.resetGiftForm = function()
    {
        ctr.gift_title   = "";
        ctr.gift_desc    = "";
        ctr.gift_link    = "";
        ctr.giftPreview = '/images/gifts/gift-holder.png';
    }
}

/* end event Controller */

/* profile controller */

profileController.$inject = ["$scope","$http","$window","wantoliErrors"];
function profileController($scope,$http,$window,wantoliErrors)
{
    var ctr  = this;
    ctr.user = [];
    
    ctr.getProfile = function(){
        $http({
              method: 'GET',
              url: '/profile/get'
            }).then(function success(response) {
                var data = response.data;
                // if(data.status)
                    ctr.user = data.user;
                
                console.log(ctr.user);
                
            }, function error(response) {
                
            });
    }
    
    $scope.uploadedFile = function(element) {
     $scope.$apply(function($scope) {
       ctr.file = element.files[0];
     });
    }
    
    ctr.updateProfile = function(){
        var url = '/profile/update'; 
        var fd = new FormData();
        if(ctr.file)
            fd.append('file',ctr.file);
            
         var data = {
            firstname   : ctr.user.firstname,
            lastname    : ctr.user.lastname,
            email       : ctr.user.email
         };
        
         fd.append("data", JSON.stringify(data));
        
         $http({
          method: 'POST',
          url: url,
          data: fd,
          withCredentials : false,
          headers : {
          'Content-Type' : undefined
          },
          transformRequest : angular.identity
         }).then(function success(response) {
            var data = response.data; 
            if(data.status)
            { 
                if(data.message)
                {
                    swal({ text :data.message, type:'success'}).then(function(){
                        if(data.reload)
                            $window.location.reload();
                    },function(){
                        if(data.reload)
                            $window.location.reload();
                    });
                }
                
            }
            else
            {
                ctr.errors = wantoliErrors.getErrors(data);
            }
          }, function error(response) {
            
          });
    }
    
    ctr.changePassword = function(){
        ctr.errors = [];
        var url = '/profile/password/update';
        
            
        var data = {
            oldPassword             : ctr.oldPassword,
            password                : ctr.password,
            password_confirmation   : ctr.password2
        }
        
        $http({
          method: 'POST',
          url: url,
          data: data
        }).then(function success(response) {
            var data = response.data;
            if(data.status)
            { 
                if(data.message)
                {
                    swal({ text :data.message, type:'success' });
                    ctr.oldPassword = ctr.password = ctr.password2 = "";
                }
                
            }
            else
            {
                if(data.message)
                    swal({ text :data.message, type:'error' });
                    
                ctr.errors = wantoliErrors.getErrors(data);
            }
          }, function error(response) {
            
          });
    };
}

/* endprofile controller */